<template>
  <div class="border-4 p-4 text-sm font-mono leading-6 text-gray-600 overflow-auto">
    <div
      v-if="messages.length === 0"
      class="text-gray-400"
      v-text="'... waiting for input'"
    />
    <template
      v-for="(log, i) in messages"
      :key="i"
    >
      <div
        class="text-gray-900 mt-2"
        :class="{ 'cursor-pointer': log.payload, 'text-red-500': log.type === 'error' }"
        @click="log.open = !log.open"
      >
        > <span class="text-gray-400 font-thin">{{ log.time }}</span> {{ log.message }} <span
          v-if="log.payload"
          class="text-gray-400 font-thin"
        >[...]</span>
      </div>
      <pre
        v-show="log.open"
        v-if="log.payload"
        class="border-dashed border-l-2 pl-4 py-2"
        v-text="log.payload"
      />
    </template>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import { Message } from '@/mixins/Terminal'

@Options({
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Terminal extends Vue {
  @Prop() messages!: Message[]
}
</script>
