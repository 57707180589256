
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'
import { mapGetters } from 'vuex'

import { Message } from '@/mixins/Terminal'

@Options({
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn'
    })
  }
})
export default class Terminal extends Vue {
  @Prop() messages!: Message[]
}
