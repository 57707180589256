<template>
  <div class="relative inline-block w-full text-gray-700">
    <select
      :id="id"
      class="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded outline-none appearance-none focus:border-black"
      placeholder="Regular input"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <slot />
    </select>
    <div class="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
      <i class="material-icons">expand_more</i>
    </div>
  </div>
</template>

<script lang="ts">
import { Prop } from 'vue-property-decorator'
import { Options, Vue } from 'vue-class-component'

@Options({})
export default class Select extends Vue {
  @Prop() modelValue!: number[]
  @Prop() id!: string
}
</script>
