import { Options, Vue } from 'vue-class-component'
import { getFormattedDate } from '@/helpers/datetime'

export interface Message {
  type: 'log' | 'error' | string
  time: string
  message: string
  payload: Record<string, any> | boolean
  open: boolean
}

@Options({ })
export default class TerminalMixix extends Vue {
  messages: Message[] = []

  get hasErrors (): boolean {
    return this.messages.some(m => m.type === 'error')
  }

  addMessage (message: string, payload: boolean | Record<string, any> = false, type: 'log' | 'error' = 'log'): void {
    this.messages.unshift({ type, time: getFormattedDate(), message, payload, open: false })
  }
}
